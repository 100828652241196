import * as R from 'ramda';
import React, { useCallback } from 'react';
// helpers/constants
import * as G from '../../../helpers';
// hooks
import { useModalAndLoaderActions, useExpandedContainerActions } from '../../../hooks';
// features
import { AuthWrapper } from '../../../features/permission/index';
// icons
import * as I from '../../../svgs';
// ui
import {
  Flex,
  AbsoluteBox,
  RelativeFlex,
  StickedWrapper,
} from '../../../ui';
// feature table
import ToggleButton from './toggle-button';
import AsyncComponent from './async-component';
import {
  Checkbox,
  TableCell,
  ActionIcon,
  FlexContainer,
  WithBoxShadow,
  CellWithBackground,
} from '../ui';
//////////////////////////////////////////////////

export const CheckBoxComponent = ({
  ml,
  id,
  item,
  name,
  checked,
  disabled,
  mainColors,
  onOptionClick,
  justifyContent,
  checkBoxCellBorderColor,
}: Object) => (
  <Flex
    ml={ml}
    height='100%'
    p='0 10px 0 5px'
    justifyContent={justifyContent}
  >
    <Checkbox
      name={name}
      type='checkbox'
      checked={checked}
      mainColors={mainColors}
      onChange={() => onOptionClick(id, item)}
      checkBoxCellBorderColor={checkBoxCellBorderColor}
      disabled={G.ifElse(G.isBoolean(disabled), disabled, false)}
    />
  </Flex>
);

export const CustomElement = ({
  key,
  name,
  data,
  width,
  field,
  rowIndex,
  component,
  callbackData,
}: Object) => {
  const actions1 = useModalAndLoaderActions();
  const actions2 = useExpandedContainerActions();

  return (
    <TableCell
      key={key}
      width={width}
      minWidth={width}
      p={R.or(field.p, '8px')}
    >
      <FlexContainer height='100%' justify='center' direction='column'>
        {component({ ...actions1, ...actions2, data, name, field, rowIndex, callbackData })}
      </FlexContainer>
    </TableCell>
  );
};

export const ActionButton = ({ guid, item, actionData }: Object) => {
  const {
    action,
    iconName,
    iconColor,
    ml = '8px',
    title = '',
    permissions,
    iconSize = [],
    getPermissions,
    disableReason = '',
    enableIfEditable = false,
  } = actionData;

  let permissionsToUse = permissions;

  if (G.isFunction(getPermissions)) permissionsToUse = getPermissions(item);

  const disabled = R.and(enableIfEditable, R.equals(R.prop('editable', item), false));

  const actionHandler = useCallback(() => {
    if (disabled) return;

    action(guid, item);
  }, [disabled, action, guid, item]);

  const iconTitle = G.ifElse(disabled, disableReason, title);

  return (
    <AuthWrapper has={permissionsToUse}>
      <ActionIcon disabled={disabled} title={iconTitle} ml={ml} onClick={actionHandler}>
        {I[iconName](R.propOr(iconColor, 'iconColor', actionData), ...iconSize)}
      </ActionIcon>
    </AuthWrapper>
  );
};

export const StickedCellWithIcons = (props: Object) => {
  const {
    item,
    toggle,
    issues,
    bgColor,
    hasIssues,
    iconColor,
    tableSettings,
    onOptionClick,
    actionButtons,
    mainLightColor,
    lastFreezedIndex,
    handleShowListIssues,
  } = props;

  const guid = G.getGuidFromObject(item);
  const draftGuid = G.getPropFromObject('draftGuid');
  const editable = R.prop('editable', item);
  const disabled = G.ifElse(G.isBoolean(editable), R.not(editable), false);

  return (
    <StickedWrapper
      left='0px'
      height='100%'
      width={tableSettings.checkBoxCellWidth}
      minWidth={tableSettings.checkBoxCellWidth}
    >
      <CellWithBackground
        bgColor={bgColor}
        highlighted={item.highlighted}
        className='cell-with-background'
        activeRowColor={tableSettings.activeRowColor}
        mainColors={G.isTrue(tableSettings.useMainColors)}
        active={R.and(G.isTrue(item.selected), G.isTrue(tableSettings.coloredActiveRow))}
      >
        <WithBoxShadow boxShadow={tableSettings.withBoxShadow || G.ifElse(R.gte(lastFreezedIndex, 0), 'unset', false)}>
          <Flex
            width='100%'
            height='100%'
            gap={R.or(tableSettings.checkBoxCellGap, 'unset')}
            justifyContent={tableSettings.checkBoxCellJustifyContent}
          >
            {
              R.and(tableSettings.expandableItems, R.not(item.notExpandable)) &&
              <ToggleButton data={item} toggle={toggle} mainColor={tableSettings.useMainColors} />
            }
            {
              R.and(
                R.not(item.disabled),
                G.isTrue(tableSettings.allowSelectItems),
              ) &&
              <CheckBoxComponent
                item={item}
                disabled={disabled}
                checked={item.selected}
                id={R.or(guid, draftGuid)}
                name={R.or(guid, draftGuid)}
                onOptionClick={onOptionClick}
                mainColors={tableSettings.useMainColors}
                checkBoxCellBorderColor={tableSettings.checkBoxCellBorderColor}
              />
            }
            {
              hasIssues &&
              <RelativeFlex
                ml='8px'
                cursor='pointer'
                onClick={(event: Object) => handleShowListIssues(event, issues)}
              >
                {I.bellIcon()}
                <AbsoluteBox
                  height={16}
                  fontSize={9}
                  minWidth={16}
                  bg={iconColor}
                  borderRadius={8}
                  border='2px solid'
                  color={mainLightColor}
                  left='calc(100% - 7px)'
                  justifyContent='center'
                  bottom='calc(100% - 7px)'
                  borderColor={mainLightColor}
                >
                  {R.length(R.values(issues))}
                </AbsoluteBox>
              </RelativeFlex>
            }
            {
              R.and(R.not(item.disabled), G.isNotNilAndNotEmpty(actionButtons)) &&
              actionButtons.map((actionData: Object, i: number) => (
                <ActionButton key={i} guid={guid} item={item} actionData={actionData} />
              ))
            }
          </Flex>
        </WithBoxShadow>
      </CellWithBackground>
    </StickedWrapper>
  );
};

export const RightStickedComponent = ({ item, rowIndex, renderRightStickedComponent }: Object) => {
  if (R.or(
    G.isTrue(item.disabled),
    R.not(G.isFunction(renderRightStickedComponent)),
  )) return <div />;

  return renderRightStickedComponent(R.assoc('rowIndex', rowIndex, item));
};

export const ExpandedDetails = (props: Object) => {
  const { item, tableSettings, callbackData } = props;

  if (G.isNilOrFalse(item.expanded)) return <div />;

  if (tableSettings.asyncChildComponent) {
    return (
      <AsyncComponent
        item={item}
        asyncOptions={{
          params: {
            guid: item.guid,
          },
        }}
        margin={tableSettings.checkBoxCellWidth}
        asyncCallback={tableSettings.asyncCallback}
        renderComponent={tableSettings.expandedDetailsComponent}
        asyncEndpoint={tableSettings.getAsyncEndpoint(item.guid)}
      />
    );
  }

  return tableSettings.expandedDetailsComponent({
    callbackData,
    parentProps: item,
    data: R.prop('details', item),
    margin: tableSettings.checkBoxCellWidth,
  });
};

export const AdditionalRowComponent = (props: Object) => {
  const { item, AdditionalRow } = props;

  if (G.isNotNilAndNotEmpty(AdditionalRow)) {
    return <AdditionalRow data={item} />;
  }

  return null;
};

