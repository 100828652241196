import React from 'react';
import * as R from 'ramda';
import { fontWeight } from 'styled-system';
// components
import { TextComponent } from '../text';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, StyledLink } from '../../ui';
//////////////////////////////////////////////////

// TODO: add common props

export const EmailsInfoPair = ({ label, emails }: Object) => {
  if (G.isNilOrEmpty(emails)) return null;

  const actionColor = G.getTheme('colors.light.blue');
  const textColor = G.getTheme('colors.greyMatterhorn');

  return (
    <Flex
      py='4px'
      fontSize={12}
      flexWrap='wrap'
      lineHeight={1.6}
      color={textColor}
      alignItems='flex-start'
    >
      <Box mr='5px' color={textColor}>
        {label}:
      </Box>
      <Box>
        {
          emails.map((email: string, index: number) => (
            <StyledLink
              key={index}
              display='block'
              fontWeight='bold'
              color={actionColor}
              wordBreak='break-all'
              href={`mailto:${email}`}
            >
              {email}
            </StyledLink>
          ))
        }
      </Box>
    </Flex>
  );
};

export const ContactInfoPair = (props: Object) => {
  if (G.isNilOrEmpty(R.prop(GC.FIELD_TEXT, props))) return null;

  const { text, phone, label, margin, lineHeight, iconMargin, autodialApp } = props;

  const actionColor = G.getTheme('colors.light.blue');
  const textColor = G.getTheme('colors.greyMatterhorn');

  let href = `mailto:${props.text}`;

  if (G.isTrue(phone)) href = G.getCallUrl(autodialApp, text);

  return (
    <Flex
      py='4px'
      m={margin}
      fontSize={12}
      color={textColor}
      lineHeight={R.or(lineHeight, 1.6)}
    >
      {G.isNotNilAndNotEmpty(label) && <Box>{label}: </Box>}
      <StyledLink
        ml='5px'
        href={href}
        display='flex'
        fontWeight='bold'
        color={actionColor}
        wordBreak='break-all'
      >
        {props.text}
        {
          props.phone &&
          <Box ml={R.or(iconMargin, '5px')}>
            {I.phone(actionColor, 10, 10)}
          </Box>
        }
      </StyledLink>
    </Flex>
  );
};

export const InfoPair = (props: Object) => {
  const {
    py,
    text,
    label,
    width,
    margin,
    flexWrap,
    fontSize,
    textColor,
    alignItems,
    fontWeight,
    lineHeight,
    marginRight,
    clickHandler,
    textMaxWidth,
    flexDirection,
    additionalInfoWrapperStyles,
    actionTextColor = null,
  } = props;

  if (G.isNilOrEmpty(text)) return null;

  const actionColor = G.getTheme('colors.light.blue');
  const withHandler = G.isFunction(clickHandler);
  const color = G.ifElse(withHandler, actionColor, actionTextColor);

  return (
    <Flex
      m={margin}
      flexWrap={flexWrap}
      py={R.or(py, '4px')}
      wordBreak='break-all'
      width={R.or(width, '100%')}
      flexDirection={flexDirection}
      fontSize={R.or(fontSize, 12)}
      css={additionalInfoWrapperStyles}
      lineHeight={R.or(lineHeight, 1.6)}
      alignItems={alignItems || 'stretch'}
      cursor={G.ifElse(withHandler, 'pointer', 'unset')}
      color={R.or(textColor, G.getTheme('colors.greyMatterhorn'))}
    >
      {label}:
      <Flex
        ml='5px'
        color={color}
        mr={marginRight}
        onClick={() => G.callFunction(clickHandler)}
      >
        <TextComponent
          title={text}
          withEllipsis={true}
          display='inline-block'
          maxWidth={R.or(textMaxWidth, 200)}
          fontWeight={R.or(fontWeight, 'bold')}
        >
          {text}
        </TextComponent>
      </Flex>
    </Flex>
  );
};

export const InfoPairWithLabelAction = ({ text, label, action, labelActionText }: Object) => {
  const actionColor = G.getTheme('colors.light.blue');
  const textColor = G.getTheme('colors.greyMatterhorn');

  return (
    <Flex
      py='4px'
      width='100%'
      fontSize={12}
      flexWrap='wrap'
      lineHeight={1.6}
      color={textColor}
    >
      <Flex>
        {label}
        <Box
          ml='5px'
          cursor='pointer'
          onClick={action}
          fontWeight='bold'
          color={actionColor}
          wordBreak='break-all'
          textDecoration='underline'
        >
          {labelActionText}:
        </Box>
      </Flex>
      <Box ml='5px' fontWeight='bold'>{text}</Box>
    </Flex>
  );
};
