import * as R from 'ramda';
import React, { useState } from 'react';
// helpers
import * as G from '../../helpers';
// ui
import {
  Label,
  Container,
  FakeLabel,
  InputField,
  FakeLabelContainer,
  InputFieldContainer,
  FakeOutlineContainer,
  FakeOutlineContainerWithoutBorder,
} from './ui';
//////////////////////////////////////////////////

const Input = (props: Object) => {
  const {
    value,
    label,
    inputRef,
    onChange,
    isFocused,
    onKeyPress,
    handleBlur,
    placeholder,
    handleFocus,
    withoutBorder,
    type = 'string',
    readOnly = false,
    inputStyles = {},
    onClick = () => {},
  } = props;

  const { width, height, maxWidth } = inputStyles;

  const isFocusedOrIsValue = R.or(isFocused, G.isNotNilAndNotEmpty(value));

  const OutlineContainer = G.isTrue(withoutBorder) ? FakeOutlineContainerWithoutBorder : FakeOutlineContainer;

  return (
    <Container width={width} height={height} maxWidth={maxWidth}>
      <Label shouldUpdateStyles={isFocusedOrIsValue}>
        { label }
      </Label>
      <InputFieldContainer>
        <InputField
          type={type}
          value={value}
          title={label}
          ref={inputRef}
          onClick={onClick}
          onChange={onChange}
          readOnly={readOnly}
          onBlur={handleBlur}
          isFocused={isFocused}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          placeholder={G.ifElse(R.and(isFocused, G.isNilOrEmpty(value)), placeholder)}
        />
        <OutlineContainer shouldUpdateStyles={isFocusedOrIsValue}>
          <FakeLabelContainer shouldUpdateStyles={isFocusedOrIsValue}>
            <FakeLabel title={label}>
              { label }
            </FakeLabel>
          </FakeLabelContainer>
        </OutlineContainer>
      </InputFieldContainer>
    </Container>
  );
};

const MuiTextInput = (props: Object) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Input
      {...props}
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      handleFocus={() => setIsFocused(true)}
      handleBlur={() => setIsFocused(false)}
    />
  );
};

const MuiTextInputForDatePicker = Input;

export {
  MuiTextInput,
  MuiTextInputForDatePicker,
};
