/*global google*/
import React from 'react';
import * as R from 'ramda';
import { compose, withHandlers } from 'react-recompose';
import { DrawingManager } from '@react-google-maps/api';
// components
import { Map, StopInfo, StopMarker, MarkerWithInfo } from '../map';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const locationsWithContent = (stops: Array) => (
  R.map((stop: Object) => ({
    ...stop,
    markerContent: <StopMarker {...stop} />,
    infoContent: <StopInfo {...stop} items={stop.items} />,
  }), stops)
);

const enhance = compose(
  withHandlers({
    handleCompletePolygon: (props: Object) => (polygon: Object) => {
      const { locations, handleCompleteSelection } = props;

      const results = R.filter(
        ({ latLng }: Object) => google.maps.geometry.poly.containsLocation(
          latLng,
          polygon,
        ),
        locations,
      );

      const callback = () => polygon.setMap(null);

      G.callFunctionWithArgs(handleCompleteSelection, { results, callback });
    },
    handleCompleteRectangle: (props: Object) => (rectangle: Object) => {
      const { locations, handleCompleteSelection } = props;

      const results = R.filter(
        ({ latLng }: Object) => rectangle.getBounds().contains(
          latLng,
          rectangle,
        ),
        locations,
      );

      const callback = () => rectangle.setMap(null);

      G.callFunctionWithArgs(handleCompleteSelection, { results, callback });
    },
  }),
);

const DrawingManagerComponent = enhance((props: Object) => (
  <DrawingManager
    onPolygonComplete={props.handleCompletePolygon}
    onRectangleComplete={props.handleCompleteRectangle}
    defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
    defaultOptions={{
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.RECTANGLE,
          // google.maps.drawing.OverlayType.CIRCLE, TODO: check if needed
        ],
      },
      polygonOptions: {
        zIndex: 1,
        editable: true,
        fillOpacity: 1,
        strokeWeight: 5,
        clickable: false,
        fillColor: '#ffff00',
      },
    }}
  />
));

const GoogleMap = (props: Object) => (
  <Map height={props.height}>
    <DrawingManagerComponent {...props} />
    {
      G.isNotNilAndNotEmpty(props.locations) && <MarkerWithInfo locations={locationsWithContent(props.locations)} />
    }
  </Map>
);

export default GoogleMap;
