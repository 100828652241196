import * as R from 'ramda';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Label } from '../label';
// features
import { AuthWrapper } from '../../features/permission';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Span, Text, Flex, BoxHovered, IconWrapper } from '../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleClick: () => (e: Event, { action, params }: Object) => {
      G.stopPropagation(e);

      if (G.isFunction(action)) {
        if (G.isNotNilAndNotEmpty(params)) {
          action(params);
        } else {
          action();
        }
      }
    },
  }),
  pure,
);

const ActionsElement = enhance((props: Object) => {
  const isThemeSecondVersion = G.isThemeSecondVersion(R.path(['version'], props));

  const textColor = G.getThemeByCond(isThemeSecondVersion, 'colors.greyMatterhorn', 'dropDown.itemTextColor');

  const hoverColor = G.getTheme('dropDown.itemTextColor');
  const hoverBackgroundColor = G.getTheme('dropDown.item.hover.bgColor');
  const fontSize = G.ifElse(isThemeSecondVersion, 13, 14);
  const optionPadding = R.pathOr('0 10px', ['optionPadding'], props);
  const lineHeight = R.pathOr(1.8, ['optionLineHeight'], props);

  return (
    <Box data-testid='testid-ActionsElement'>
      {
        props.options.map((option: Object, i: number) => (
          <AuthWrapper
            key={i}
            has={option.permissions}
            notHas={option.notHasPermissions}
          >
            <BoxHovered
              width='100%'
              cursor='pointer'
              p={optionPadding}
              color={textColor}
              alignItems='center'
              fontSize={fontSize}
              lineHeight={lineHeight}
              hoverColor={hoverColor}
              hoverBackgroundColor={hoverBackgroundColor}
              data-testId='testid-ActionsElement-BoxHovered'
              onClick={(e: Object) => props.handleClick(e, option)}
            >
              <Label
                {...option}
                endLabel={option.endLabel}
                frontLabel={option.frontLabel}
              >
                <Span>{option.text}</Span>
              </Label>
            </BoxHovered>
          </AuthWrapper>
        ))
      }
    </Box>
  );
});

const ActionsElement2 = (props: Object) => {
  const { options } = props;

  return (
    <Flex data-testid='testid-ActionsElement2'>
      {
        options.map((option: Object, i: number) => (
          <AuthWrapper
            key={i}
            has={option.permissions}
            notHas={option.notHasPermissions}
          >
            <Fragment>
              {
                option.withTitle && <Text fontSize={13}>{option.text}</Text>
              }
              <IconWrapper
                cursor='pointer'
                title={option.text}
                onClick={option.action}
                ml={option.iconMl || 15}
                data-testid='testid-ActionsElement2-IconWrapper'
              >
                {option.frontIcon}
              </IconWrapper>
            </Fragment>
          </AuthWrapper>
        ))
      }
    </Flex>
  );
};

export {
  ActionsElement,
  ActionsElement2,
};

