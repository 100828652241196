import React from 'react';
import * as R from 'ramda';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withHandlers,
} from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { ReactSelect } from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

const setSearchCriteria = (searchText: string = null) => {
  let filter = [
    {
      operation: 'equal',
      dataType: 'boolean',
      booleanValue: true,
      propertyName: GC.FIELD_CARRIER_ACTIVE,
    },
  ];

  if (G.isNotNil(searchText)) {
    const searchFilter = {
      dataType: 'string',
      operation: 'contain',
      stringValue: searchText,
      propertyName: GC.FIELD_NAME,
    };

    filter = R.append(searchFilter, filter);
  }

  return filter;
};

const setRequestParams = ({ carriers, branchGuid }: Object, isPagination: boolean = false, searchText: string) => ({
  limit: 10,
  searchCriteria: setSearchCriteria(searchText),
  [GC.FIELD_CURRENT_BRANCH]: R.or(branchGuid, G.getAmousCurrentBranchGuidFromWindow()),
  offset: G.ifElse(
    R.and(isPagination, G.isNotNilAndNotEmpty(carriers)),
    R.length(carriers),
    0,
  ),
  orderFields: [
    {
      sequence: 1,
      order: 'ASC',
      reference: false,
      name: GC.FIELD_CARRIER_NAME,
    },
  ],
  fields: [
    {
      sequence: 1,
      name: GC.FIELD_GUID,
    },
    {
      sequence: 2,
      name: GC.FIELD_CARRIER_NAME,
    },
  ],
});

const handleLoadCarrierOptions = async (props: Object) => {
  const { setCarriers, setTotalCount, setLoadingStatus, endpointName = 'carrierListParentAndCurrent' } = props;

  const options = {
    data: setRequestParams(props),
  };

  const res = await sendRequest('post', R.prop(endpointName, endpointsMap), options);

  const { data } = res;

  setCarriers((prevCarriers: Array) => R.concat(prevCarriers, data.results));
  setTotalCount(data.totalCount);
  setLoadingStatus(false);
};

const handleGetCarrierList = async (props: Object) => {
  const { carriers, setCarriers, setLoadingStatus, endpointName = 'carrierListParentAndCurrent' } = props;

  const options = {
    data: setRequestParams(props, true),
  };

  const res = await sendRequest('post', R.prop(endpointName, endpointsMap), options);

  const { data } = res;

  const newOptions = R.concat(carriers, R.or(data.results, []));

  setCarriers(newOptions);
  setLoadingStatus(false);
};

const handleAsyncSearch = async (props: Object, searchText: string) => {
  const { setCarriers, setTotalCount, setLoadingStatus, endpointName = 'carrierListParentAndCurrent' } = props;

  const options = {
    data: setRequestParams(props, false, searchText),
  };

  const res = await sendRequest('post', R.prop(endpointName, endpointsMap), options);

  const { data } = res;

  setCarriers(data.results);
  setTotalCount(data.totalCount);
  setLoadingStatus(false);
};

const enhance = compose(
  withState('carriers', 'setCarriers', []),
  withState('searchText', 'setText', null),
  withState('totalCount', 'setTotalCount', null),
  withState('isLoading', 'setLoadingStatus', false),
  withState('selectedOptions', 'setSelectedOptions', []),
  withHandlers({
    handleChange: ({ isMulti, fieldName, setFieldValue, setSelectedOptions }: Object) => (value: Object) => {
      if (R.isNil(value)) return setFieldValue(fieldName, null);

      if (G.isTrue(isMulti)) {
        const data = R.map(R.prop(GC.FIELD_VALUE), value);

        setSelectedOptions(value);
        setFieldValue(fieldName, data);
      } else {
        setFieldValue(fieldName, R.prop(GC.FIELD_VALUE, value));
      }
    },
    handleScroll: (props: Object) => () => {
      const { carriers, totalCount, setLoadingStatus } = props;

      if (R.gt(totalCount, R.length(carriers))) {
        setLoadingStatus(true);
        handleGetCarrierList(props);
      }
    },
    handleGetCarrierOptions: (props: Object) => () => {
      const { totalCount, setLoadingStatus } = props;

      if (R.isNil(totalCount)) {
        setLoadingStatus(true);
        handleLoadCarrierOptions(props);
      }
    },
    handleSearch: (props: Object) => (search: string) => {
      const { setText, searchText, setLoadingStatus } = props;

      const cond = R.not(R.and(G.isNilOrEmpty(searchText), R.isEmpty(search)));

      if (cond) {
        setLoadingStatus(true);
        handleAsyncSearch(props, search, props.field);
        setText(search);
      }
    },
    handleSetInitialCarrier: (props: Object) => async () => {
      const { value, setCarriers, setLoadingStatus } = props;

      if (G.isNilOrEmpty(value)) return;

      try {
        const res = await sendRequest('get', endpointsMap.getCarrierEndpoint(value));

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          setCarriers(R.of([], data));
        }
      } catch (error) {
        G.handleException(error, 'handleSetInitialCarrier');
      } finally {
        setLoadingStatus(false);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleSetInitialCarrier();
    },
  }),
  pure,
);

export const SelectCarriers = enhance(({
  mr,
  value,
  width,
  height,
  isMulti,
  carriers,
  disabled,
  hasError,
  fieldName,
  isLoading,
  placeholder,
  handleChange,
  handleScroll,
  useMaxHeight,
  handleSearch,
  selectedOptions,
  isClearable = false,
  handleGetCarrierOptions,
  valueContainerMaxHeight,
}: Object) => {
  const options = G.mapNameGuidObjectPropsToLabelValueObject(R.or(carriers, []));

  return (
    <ReactSelect
      value={value}
      id={fieldName}
      height={height}
      isMulti={isMulti}
      hasError={hasError}
      isDisabled={disabled}
      isLoading={isLoading}
      onChange={handleChange}
      isClearable={isClearable}
      placeholder={placeholder}
      useMaxHeight={useMaxHeight}
      onInputChange={handleSearch}
      onMenuScrollToBottom={handleScroll}
      onMenuOpen={handleGetCarrierOptions}
      valueContainerMaxHeight={valueContainerMaxHeight}
      options={R.uniqBy(R.prop(GC.FIELD_VALUE), R.concat(selectedOptions, options))}
      additionalStyles={{
        container: (baseStyles: Object) => ({
          ...baseStyles,
          width,
          marginRight: mr,
        }),
      }}
      // NOTE: not working
      // onBlurResetsInput={false}
      // onCloseResetsInput={false}
    />
  );
});
