import styled from 'styled-components';
import { width, space, fontSize } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const CustomMarker = styled.div`
  width: 36px;
  height: 36px;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  transition: 0.2s transform;
  background-color: ${({ bg }: Object) => bg || G.getTheme('map.bgColor')};
  border: 5px solid ${({ color }: Object) => color || G.getTheme('map.markerColor')};
  transform: ${({ active, transform }: Object) => `${G.ifElse(active, 'scale(1.1)', 'scale(1)')} ${transform}`};
  &:hover {
    transform: ${({ transform }: Object) => `scale(1.1) ${transform}`};
  }
  &:after {
    top: 26px;
    right: 50%;
    content: '';
    position: absolute;
    transform: translateX(50%);
    border: 13px solid transparent;
    border-top: 18px solid ${({ color }: Object) => color || G.getTheme('map.markerColor')};
  }
`;

export const CustomMarkerContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
`;

export const CustomSvgMarker = styled.div`
  cursor: pointer;
`;

export const CustomSvgMarkerContent = styled.div`
  overflow: hidden;
  width: max-content;
`;

export const CloseButton = styled.div`
  right: 5px;
  top: -20px;
  padding: 7px;
  cursor: pointer;
  position: absolute;
  &:before {
    content: '';
    width: 10px;
    height: 2px;
    position: absolute;
    transform: rotate(45deg);
    background-color: ${({ color }: Object) => color || G.getTheme('map.markerColor')};
  }
  &:after {
    content: '';
    width: 10px;
    height: 2px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: ${({ color }: Object) => color || G.getTheme('map.markerColor')};
  }
`;

export const CustomInfoWindow = styled.div`
  left: 50%;
  top: -10px;
  height: auto;
  cursor: default;
  min-width: 170px;
  width: max-content;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(173, 173, 173, 0.5);
  padding: ${({ p }: Object) => p || '5px 20px 5px 5px'};
  background-color: ${() => G.getTheme('map.infoBoxBgColor')};
  max-width:  ${({ maxWidth }: Object) => maxWidth || '300px'};
  transform: ${({ transform }: Object) => transform || 'translate(-50%, -100%)'};
  border: solid 1px ${({ infoBorderColor }: Object) => infoBorderColor || G.getTheme('map.infoBoxBorderColor')};
`;

export const StopMarkerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  color: #2e6c9c;
  font-weight: bold;
  align-items: center;
  justify-content: center;
`;

export const StopInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  ${width}
  ${space}
  ${fontSize}

  color: ${({ color }: Object) => color};
  font-weight: ${({ fontWeight }: Object) => fontWeight};
`;

export const FlexWrapper = styled.div`
  ${width}
  ${space}

  display: flex;
  transform: ${({ transform }: Object) => transform};
`;

