import * as R from 'ramda';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import Popper from '@mui/material/Popper';
import { OuterClick } from 'react-outer-click';
import { createStructuredSelector } from 'reselect';
import { Field, change, reduxForm } from 'redux-form';
import React, { useMemo, useState, Fragment, useCallback } from 'react';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { HoveringTitle } from '../hovering-title';
import { renderSelect } from '../edit-report/report-common';
import { getAvailableFilterFields } from '../edit-report/helpers';
// features
import { makeSelectAvailableReportGeoFencingZoneList } from '../../features/geo-fencing-zone/report/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Form, Fieldset2, renderFormGroup } from '../../forms';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, ActionButton, IconWrapper } from '../../ui';
// component filter
import MultiSelect from './multi-select';
import { FILTER_FORM } from './constants';
import { customRequired } from './validation';
import { makeSelectFormValues } from './selectors';
import { FilterDate, inputGroup, FilterWrapper } from './ui';
import { reportConditions, newReportConditions, reportBooleanValues } from './settings';
//////////////////////////////////////////////////

// TODO: remove or uncomment after testing
// const renderDefault = (props: Object) => {
//   const { version, operation } = props;

//   if (R.equals(operation, 'range')) {
//     return (
//       <Flex>
//         {
//           ['from', 'to'].map((field: string, i: number) => (
//             <Field
//               key={i}
//               width={106}
//               type='text'
//               name={field}
//               direction='row'
//               margin='5px 10px'
//               version={version}
//               component={renderFormGroup('text')}
//               placeholder={G.getWindowLocale(`titles:${field}`, field)}
//             />
//           ))
//         }
//       </Flex>
//     );
//   }

//   return (
//     <Field
//       type='text'
//       name='value'
//       width='232px'
//       margin='5px 10px'
//       version={version}
//       direction='column'
//       component={renderFormGroup('text')}
//       placeholder={G.getWindowLocale('titles:enter-value', 'Enter a Value')}
//     />
//   );
// };

// const renderNumber = (props: Object) => {
//   const { version, operation } = props;

//   if (R.equals(operation, 'range')) {
//     return (
//       <Flex>
//         {
//           ['from', 'to'].map((field: string, i: number) => (
//             <Field
//               key={i}
//               width={106}
//               name={field}
//               type='number'
//               direction='row'
//               margin='5px 10px'
//               version={version}
//               component={renderFormGroup('text')}
//               placeholder={G.getWindowLocale(`titles:${field}`, field)}
//             />
//           ))
//         }
//       </Flex>
//     );
//   }

//   return (
//     <Field
//       name='value'
//       type='number'
//       width='232px'
//       margin='5px 10px'
//       version={version}
//       direction='column'
//       component={renderFormGroup('text')}
//       placeholder={G.getWindowLocale('titles:enter-value', 'Enter a Value')}
//     />
//   );
// };

// const renderBoolean = () => (
//   <Field
//     width={232}
//     name='value'
//     type='select'
//     margin='5px 10px'
//     direction='column'
//     validate={G.required}
//     component={renderFormGroup('select')}
//     options={R.tail(reportBooleanValues())}
//     placeholder={G.getWindowLocale('titles:enter-value', 'Enter a value')}
//     firstOptionConfig={{
//       disabled: true,
//       [GC.FIELD_TEXT]: G.getWindowLocale('titles:choose-value', 'Choose a value'),
//     }}
//   />
// );

// const getPlaceholder = (placeholder: Object) => G.ifElse(
//   G.isNotNilAndNotEmpty(placeholder),
//   placeholder,
//   {
//     text: 'Choose',
//     key: 'titles:choose',
//   },
// );

// const renderSelectMultiple = (props: Object, field: object, index: number) => {
//   const activeSearchField = R.or(R.path(['formValues', 'field'], props), R.path(['propertyName'], field));

//   let searchOptions;

//   if (G.isNilOrEmpty(R.path(['filterBy'], props))) {
//     searchOptions = R.find(R.propEq(activeSearchField, 'value'))(R.path(['availableFields'], props));
//   } else {
//     searchOptions = R.find(R.propEq(activeSearchField, 'name'))(R.path(['filterBy'], props));
//   }

//   if (G.isNilOrEmpty(searchOptions)) return null;

//   const { options, placeholder } = searchOptions;

//   const { version } = props;

//   const { key, text } = getPlaceholder(placeholder);

//   return (
//     <Field
//       zI={10}
//       width='232px'
//       display='flex'
//       sequence={index}
//       inputWidth='100%'
//       options={options}
//       margin='5px 10px'
//       version={version}
//       direction='column'
//       labelFontSize={14}
//       labelMargin='5px 0'
//       labelPosition='left'
//       type='selectMultiple'
//       name='selectedStatus'
//       change={props.change}
//       errorMargin='0 0 5px 0'
//       component={MultiSelect}
//       quickFilterInReport={false}
//       placeholder={G.getWindowLocale(key, text)}
//     />
//   );
// };

// const renderDate = (props: Object) => {
//   const { version, operation, formValues } = props;

//   if (G.notEquals(operation, 'date-range')) {
//     return ['last', 'next'].map((field: string, i: number) => (
//       <Field
//         key={i}
//         type='text'
//         width={232}
//         name={field}
//         display='flex'
//         direction='row'
//         margin='5px 10px'
//         version={version}
//         errorFontSize={14}
//         errorMargin='0 5px'
//         validate={G.isEmptyOrNumeric}
//         component={renderFormGroup('text')}
//         placeholder={G.getWindowLocale(`titles:${field}`, field)}
//       />
//     ));
//   }

//   return ['from', 'to'].map((field: string, i: number) => {
//     let maxDate;
//     let minDate;
//     const isFrom = R.and(R.equals(field, 'from'), G.isNotNilAndNotEmpty(formValues.to));

// if (isFrom) maxDate = moment(formValues.to).startOf('day');

// if (R.equals(field, 'to')) minDate = moment(formValues.from).endOf('day');

//     return (
//       <Field
//         key={i}
//         type='text'
//         zIndex={12}
//         width={232}
//         name={field}
//         direction='row'
//         margin='5px 10px'
//         version={version}
//         minDate={minDate}
//         maxDate={maxDate}
//         disablePortal={true}
//         withCalendarIcon={false}
//         additionClass={inputGroup}
//         withoutCalendarLabel={true}
//         component={renderFormGroup('datePicker')}
//         placeholder={G.getWindowLocale(`titles:${field}`, field)}
//       />
//     );
//   });
// };

// const renderSingleSelect = (props: Object, field: Object) => {
//   const activeSearchField = R.or(R.path(['formValues', 'field'], props), R.path(['propertyName'], field));
//   let searchOptions;

//   if (G.isNilOrEmpty(R.path(['filterBy'], props))) {
//     searchOptions = R.find(R.propEq(activeSearchField, 'value'))(R.path(['availableFields'], props));
//   } else {
//     searchOptions = R.find(R.propEq(activeSearchField, 'name'))(R.path(['filterBy'], props));
//   }

//   if (G.isNilOrEmpty(searchOptions)) return null;

//   const { placeholder } = searchOptions;
//   const { change, version } = props;

//   const options = R.compose(
//     R.map((option: Object) => {
//       const { name, label } = option;

//       return R.assoc(GC.FIELD_NAME, R.or(label, name), option);
//     }),
//     R.pathOr([], ['options']),
//   )(searchOptions);

//   return (
//     <Field
//       zI={10}
//       margin={10}
//       name='value'
//       options={options}
//       version={version}
//       component={renderSelect}
//       placeholder={G.getWindowLocale(placeholder.key, placeholder.text)}
//       selectAction={(name: string, { value }: Object) => change(name, value)}
//     />
//   );
// };

// const renderField = (
//   props: Object,
//   field: Object,
//   sectionName: string,
//   index: number,
// ) => {
//   const fields = {
//     number: renderNumber,
//     boolean: renderBoolean,
//     default: renderDefault,
//     date: () => <FilterDate>{renderDate(props)}</FilterDate>,
//     'string:select': () => renderSingleSelect(props, field, index),
//     selectMultiple: () => renderSelectMultiple(props, field, index),
//   };

//   const param = G.ifElse(
//     R.isNil(fields[props.dataType]),
//     'default',
//     props.dataType,
//   );

//   return fields[param](props);
// };

// const formEnhance = compose(
//   reduxForm({
//     change,
//     form: FILTER_FORM,
//   }),
//   withState('dataType', 'setDataType', ''),
//   withState('operation', 'setOperation', ''),
//   withHandlers({
//     handleFormSubmit: (props: Object) => (values: any) => {
//       const {
//         type,
//         dataType,
//         filterBy,
//         operation,
//         setDataType,
//         filterAction,
//         setOperation,
//         setFilterParam,
//         setFilterStatus,
//       } = props;
//       const {
//         to,
//         from,
//         last,
//         next,
//         value,
//         field,
//         selectedStatus,
//       } = values;

//       const nameValue = R.find(R.propEq(field, 'name'), filterBy);
//       const splittedDataType = R.head(R.split(':', dataType));
//       const keyName = `${splittedDataType}Value`;

//       const fieldName = G.ifElse(
//         R.equals(dataType, GC.FIELD_REFERENCE),
//         GC.FIELD_REFERENCE_NAME,
//         'propertyName',
//       );

//       const checkValue = G.isNotNilAndNotEmpty(selectedStatus);
//       const isDateRange = R.equals(operation, GC.FIELD_DATE_RANGE);

//       const params = G.ifElse(
//         R.equals(dataType, 'date'),
//         {
//           last,
//           next,
//           dateRelative: R.not(isDateRange),
//           operation: G.ifElse(isDateRange, operation, ''),
//           timeUnit: G.ifElse(isDateRange, null, operation),
//           to: moment(to).format(GC.DEFAULT_DATE_TIME_FORMAT),
//           from: moment(from).format(GC.DEFAULT_DATE_TIME_FORMAT),
//         },
//         {
//           [keyName]: G.ifElse(G.isTrue(checkValue), selectedStatus, value),
//         },
//       );

//       const isRef = R.equals(dataType, GC.FIELD_REFERENCE);

//       let reqBody = {
//         operation,
//         dateRelative: false,
//         propertyName: G.ifElse(isRef, GC.FIELD_REFERENCES, ''),
//         [fieldName]: nameValue.value,
//         ...params,
//         dataType: splittedDataType,
//       };

//       if (isRef) {
//         reqBody = R.assoc(
//           GC.FIELD_REFERENCE_FIELD_NAME,
//           R.pathOr(null, [GC.FIELD_REFERENCE_FIELD_NAME], nameValue),
//           reqBody,
//         );
//       }

//       if (G.isFilterNumberRange(props)) {
//         reqBody = {
//           ...reqBody,
//           numberValue: null,
//           numberRange: { to: G.toNumber(to), from: G.toNumber(from) },
//         };
//       }

//       if (G.isFilterStringRange(props)) {
//         reqBody = {
//           ...reqBody,
//           stringValue: null,
//           stringRange: { to, from },
//         };
//       }

//       if (G.isFilterValuesBoolean(props)) {
//         reqBody = R.assoc(GC.FIELD_BOOLEAN_VALUE, R.equals(value, 'true'), reqBody);
//       }

//       setFilterParam(reqBody);
//       filterAction({ reqBody, type });
//       setDataType('');
//       setOperation('');
//       setFilterStatus(false);
//     },
//     handleOperation: ({ change, setOperation }: Object) => (columnName: string, { name, value }: Object) => {
//       change(columnName, name);
//       setOperation(value);
//     },
//     handleFilterFieldSelect: ({ change, setDataType }: Object) => (columnName: string, { name, type }: Object) => {
//       change(columnName, name);
//       setDataType(type);
//     },
//   }),
//   pure,
// );

const getConditionOptions = (props: Object) => {
  const { type, dataType } = props;

  const newConditionReportTypes = [GC.CLO_REPORT, GC.TEL_REPORT];

  const conditionsFunc = G.ifElse(
    R.includes(type, newConditionReportTypes),
    newReportConditions,
    reportConditions,
  );

  return conditionsFunc()[R.or(dataType, 'default')];
};

const labelStyles = {
  display: 'none',
};

const inputWrapperStyles = {
  mb: 10,
  width: 230,
};

const whiteColor = G.getTheme('colors.white');
const darkBlueColor = G.getTheme('colors.dark.blue');

const rangeOperations = ['range', 'date-range'];

const validationSchema = Yup.lazy(({ operation }: Object) => Yup.object().shape({
  filter: G.yupStringRequired,
  operation: G.yupStringRequired,
  to: G.ifElse(R.includes(operation, rangeOperations), G.yupStringRequired),
  from: G.ifElse(R.includes(operation, rangeOperations), G.yupStringRequired),
}));

const FilterPopper = (props: Object) => {
  const {
    type,
    open,
    zIndex,
    position,
    filterBy,
    anchorEl,
    disablePortal,
    handleTogglePopper,
    shouldSubmitFilterForm,
  } = props;

  const onSubmit = useCallback((values: Object) => {
    const {
      type,
      filterBy,
      filterAction,
      setFilterParam,
    } = props;

    const {
      to,
      from,
      last,
      next,
      value,
      filter,
      operation,
    } = values;

    const { type: dataType, referenceFieldName } = R.or(R.find(R.propEq(filter, GC.FIELD_VALUE), filterBy), {});

    const splittedDataType = R.head(R.split(':', dataType));
    const keyName = `${splittedDataType}Value`;

    const fieldName = G.ifElse(
      R.equals(dataType, GC.FIELD_REFERENCE),
      GC.FIELD_REFERENCE_NAME,
      'propertyName',
    );

    const isDateRange = R.equals(operation, GC.FIELD_DATE_RANGE);

    let valueToUse = value;

    if (G.isArray(value)) {
      valueToUse = R.compose(
        R.filter((option: Object) => R.includes(option.value, value)),
        R.path(['options']),
        R.find(R.propEq(filter, 'value')),
      )(filterBy);
    }

    const params = G.ifElse(
      R.equals(dataType, 'date'),
      {
        last,
        next,
        dateRelative: R.not(isDateRange),
        operation: G.ifElse(isDateRange, operation, ''),
        timeUnit: G.ifElse(isDateRange, null, operation),
        to: moment(G.ifElse(G.isNotNilAndNotEmpty(to), to)).format(GC.DEFAULT_DATE_TIME_FORMAT),
        from: moment(G.ifElse(G.isNotNilAndNotEmpty(from), from)).format(GC.DEFAULT_DATE_TIME_FORMAT),
      },
      {
        [keyName]: valueToUse,
      },
    );

    const isRef = R.equals(dataType, GC.FIELD_REFERENCE);

    let reqBody = {
      operation,
      dateRelative: false,
      propertyName: G.ifElse(isRef, GC.FIELD_REFERENCES, ''),
      // NOTE: important
      [fieldName]: filter,
      ...params,
      dataType: splittedDataType,
    };

    if (isRef) {
      reqBody = R.assoc(
        GC.FIELD_REFERENCE_FIELD_NAME,
        R.or(referenceFieldName, null),
        reqBody,
      );
    }

    if (G.isFilterNumberRange({ dataType, operation })) {
      reqBody = {
        ...reqBody,
        numberValue: null,
        numberRange: { to: G.toNumber(to), from: G.toNumber(from) },
      };
    }

    if (G.isFilterStringRange({ dataType, operation })) {
      reqBody = {
        ...reqBody,
        stringValue: null,
        stringRange: { to, from },
      };
    }

    if (G.isFilterValuesBoolean({ dataType, operation })) {
      reqBody = R.assoc(GC.FIELD_BOOLEAN_VALUE, G.isTrue(value), reqBody);
    }

    setFilterParam(reqBody);
    filterAction({ reqBody, type });
    handleTogglePopper();
  }, []);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      to: '',
      from: '',
      last: '',
      next: '',
      value: '',
      filter: null,
      operation: null,
    },
  });

  const { submitForm, handleSubmit, values: { filter, operation } } = formik;

  const handleClick = () => {
    if (shouldSubmitFilterForm) submitForm();
  };

  const fieldSettings = useMemo(() => {
    const filterByOptions = R.compose(
      R.map(({ name, value }: Object) => ({ value, [GC.FIELD_LABEL]: name })),
      R.filter(({ disableFilter }: Object) => R.not(disableFilter)),
    )(filterBy);

    const { options, placeholder, type: dataType } = R.or(R.find(R.propEq(filter, GC.FIELD_VALUE), filterBy), {});

    const placeholderText = G.getWindowLocale(...G.isObject(placeholder) ? R.values(placeholder) : ['titles:choose']);

    const makeFilterValueFieldSettings = () => {
      const dataTypeMap = {
        default: { type: 'text' },
        number: { type: 'number' },
        date: {
          type: 'datePicker',
          disablePortal: true,
        },
        boolean: {
          type: 'reactSelect',
          placeholder: placeholderText,
          options: [
            {
              value: true,
              label: G.getWindowLocale('titles:yes', 'Yes'),
            },
            {
              value: false,
              label: G.getWindowLocale('titles:no', 'No'),
            },
          ],
        },
        'string:select': {
          options,
          type: 'reactSelect',
          placeholder: placeholderText,
        },
        selectMultiple: {
          options,
          isMulti: true,
          type: 'reactSelect',
          placeholder: placeholderText,
        },
      };

      const fieldOptions = R.prop(dataType, dataTypeMap);

      if (R.includes(operation, rangeOperations)) {
        const mb = G.ifElse(R.equals(operation, 'date-range'), 10);
        const width = G.ifElse(R.equals(operation, 'date-range'), 230, 110);

        return [
          {
            ...fieldOptions,
            labelStyles,
            errorWidth: '0px',
            fieldName: 'from',
            inputWrapperStyles: { mb, width },
            placeholder: G.getWindowLocale('titles:from', 'From'),
            maxDate: ({ values }: Object) => moment(R.propOr(null, 'to', values)).startOf('day'),
          },
          {
            ...fieldOptions,
            labelStyles,
            fieldName: 'to',
            errorWidth: '0px',
            inputWrapperStyles: { width },
            placeholder: G.getWindowLocale('titles:to', 'To'),
            minDate: ({ values }: Object) => moment(R.propOr(null, 'from', values)).startOf('day'),
          },
        ];
      }

      if (R.and(G.notEquals(operation, 'date-range'), R.equals(dataType, 'date'))) {
        return [
          {
            ...fieldOptions,
            type: 'number',
            errorWidth: '0px',
            fieldName: 'last',
            inputWrapperStyles: { width: 110 },
            placeholder: G.getWindowLocale('titles:last', 'Earlier'),
          },
          {
            ...fieldOptions,
            type: 'number',
            fieldName: 'next',
            errorWidth: '0px',
            inputWrapperStyles: { width: 110 },
            placeholder: G.getWindowLocale('titles:next', 'Later'),
          },
        ];
      }

      return [
        {
          ...fieldOptions,
          labelStyles,
          errorWidth: '0px',
          fieldName: 'value',
          inputWrapperStyles: { width: 230 },
          placeholder: G.getWindowLocale('titles:enter-a-value', 'Enter a Value'),
        },
      ];
    };

    const handleFilterChange = (value: string, _: Object, { values, setValues }: Object) => {
      const { type: nextDataType } = R.or(R.find(R.propEq(value, GC.FIELD_VALUE), filterBy), {});

      const { operation: prevOperation } = values;

      setValues({
        to: '',
        from: '',
        last: '',
        next: '',
        value: '',
        filter: value,
        operation: G.ifElse(
          R.equals(getConditionOptions({ type, dataType }), getConditionOptions({ type, dataType: nextDataType })),
          prevOperation,
          null,
        ),
      });
    };

    const settings = [
      {
        labelStyles,
        errorWidth: '0px',
        inputWrapperStyles,
        type: 'reactSelect',
        fieldName: 'filter',
        options: filterByOptions,
        shouldCustomChange: true,
        customChangeHandler: handleFilterChange,
        placeholder: G.getWindowLocale('titles:type-to-search', 'Type to search'),

      },
      {
        labelStyles,
        errorWidth: '0px',
        inputWrapperStyles,
        type: 'reactSelect',
        fieldName: 'operation',
        getOptionLabel: R.prop(GC.FIELD_NAME),
        options: getConditionOptions({ type, dataType }),
        placeholder: G.getWindowLocale('titles:condition', 'Condition'),
      },
      ...makeFilterValueFieldSettings(),
    ];

    return settings;
  }, [filter, operation]);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      disablePortal={disablePortal}
      sx={{ zIndex: G.ifElse(disablePortal, zIndex, 1300) }}
      placement={G.ifElse(position, position, 'bottom-end')}
    >
      <OuterClick as={FilterWrapper} onOuterClick={() => handleTogglePopper()}>
        <Form onSubmit={handleSubmit}>
          <Fieldset2
            {...formik}
            fields={fieldSettings}
            fieldsWrapperStyles={{ m: 10, width: 230, justifyContent: 'space-between' }}
          />
          <ActionButton
            m={10}
            height={32}
            fontSize={14}
            borderRadius='5px'
            bgColor={whiteColor}
            onClick={handleClick}
            textTransform='uppercase'
            textColor={darkBlueColor}
            border={`1px solid ${darkBlueColor}`}
            type={G.ifElse(shouldSubmitFilterForm, 'button', 'submit')}
          >
            {G.getWindowLocale('actions:find', 'Find')}
          </ActionButton>
        </Form>
      </OuterClick>
    </Popper>
  );
};

// TODO: remove or uncomment after testing
// const FilterPopper = formEnhance((props: Object) => {
//   const {
//     version,
//     position,
//     filterBy,
//     handleSubmit,
//     handleOperation,
//     handleFormSubmit,
//     shouldSubmitFilterForm,
//     handleFilterFieldSelect,
//   } = props;

//   console.log('filterBy', filterBy);

//   const whiteColor = G.getTheme('colors.white');
//   const darkBlueColor = G.getTheme('colors.dark.blue');

//   return (
//     <Popper placement={G.ifElse(position, position, 'bottom-end')}>
//       <Form onSubmit={handleSubmit(handleFormSubmit)}>
//         <Field
//           name='field'
//           margin='10px'
//           version={version}
//           component={renderSelect}
//           validate={customRequired}
//           selectAction={handleFilterFieldSelect}
//           placeholder={G.getWindowLocale('titles:type-to-search', 'Type to search')}
//           options={R.filter(({ disableFilter }: Object) => R.not(disableFilter), filterBy)}
//         />
//         <Field
//           margin='10px'
//           name='operation'
//           version={version}
//           component={renderSelect}
//           validate={customRequired}
//           selectAction={handleOperation}
//           options={getConditionOptions(props)}
//           placeholder={G.getWindowLocale('titles:condition', 'Condition')}
//         />
//         <Box zIndex='0'>
//           {renderField(props)}
//         </Box>
//         <ActionButton
//           m={10}
//           height={32}
//           fontSize={14}
//           borderRadius='5px'
//           bgColor={whiteColor}
//           textTransform='uppercase'
//           textColor={darkBlueColor}
//           border={`1px solid ${darkBlueColor}`}
//           type={G.ifElse(shouldSubmitFilterForm, 'button', 'submit')}
//           onClick={G.ifElse(shouldSubmitFilterForm, handleSubmit(handleFormSubmit), null)}
//         >
//           {G.getWindowLocale('actions:find', 'Find')}
//         </ActionButton>
//       </Form>
//     </Popper>
//   );
// });

const Component = (props: Object) => {
  const {
    version,
    usedReport,
    filterColor,
    geoFencingZones,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleTogglePopper = (event: Object) => setAnchorEl(event ? event.currentTarget : null);

  const color = G.getThemeByCond(
    G.isThemeSecondVersion(version),
    'colors.dark.blue',
    'icons.iconColor',
  );

  const filterBy = getAvailableFilterFields({
    usedReport,
    geoFencingZones,
    availableFields: R.pathOr([], ['filterBy'], props),
  });

  return (
    <Fragment>
      <HoveringTitle
        handleClick={handleTogglePopper}
        title={G.getWindowLocale('titles:quick-filter', 'Quick Filter')}
        positionConfig={{
          zIndex: 14,
          right: '70%',
          bottom: '110%',
          width: 'max-content',
        }}
      >
        <IconWrapper
          onClick={handleTogglePopper}
          title={G.getWindowLocale('titles:quick-filter', 'Quick Filter')}
        >
          {I.filter(R.or(filterColor, color))}
        </IconWrapper>
      </HoveringTitle>
      <FilterPopper
        {...props}
        filterBy={filterBy}
        anchorEl={anchorEl}
        open={R.isNotNil(anchorEl)}
        handleTogglePopper={handleTogglePopper}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  // formValues: makeSelectFormValues(state),
  geoFencingZones: makeSelectAvailableReportGeoFencingZoneList(state),
});

export const Filter = connect(mapStateToProps)(Component);

export default Filter;
