import * as R from 'ramda';
import Popper from '@mui/material/Popper';
import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react';
// mui
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// components
import { MuiTextInputForDatePicker } from '../mui-text-input';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const useDatePickerHandlers = ({ onOpen, onClose }: Object) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(true);


    G.callFunction(onOpen);
  }, [onOpen]);

  const handleClose = useCallback(() => {
    if (G.isFalse(isOpen)) return;

    setIsOpen(false);

    G.callFunction(onClose);
  }, [isOpen, onClose]);

  return {
    isOpen,
    setIsOpen,
    handleClose,
    handleClick,
  };
};

const DatePicker = (props: Object) => {
  const {
    m,
    value,
    label,
    width,
    isOpen,
    setIsOpen,
    handleClick,
    wrapperWidth,
    withoutBorder,
    inputSettings,
    placeholder = '',
    onChange = () => {},
    onAccept = () => {},
    isClearable = false,
    popperPlacement = 'bottom-end',
  } = props;

  const ref = useRef(null);

  const initialAnimation = { opacity: 0, transform: 'scale(0.75, 0.5625)' };

  const [{ opacity, transform }, setAnimation] = useState(initialAnimation);

  const handleClose = () => {
    if (G.isFalse(isOpen)) return;

    setAnimation(initialAnimation);
  };

  const handleAccept = (value: Object) => {
    onAccept(value);
    handleClose();
  };

  useEffect(() => {
    if (G.isFalse(isOpen)) return;

    const timer = setTimeout(() => setAnimation({ opacity: 1, transform: 'none' }), 0);

    return () => clearTimeout(timer);
  }, [isOpen]);

  useEffect(() => {
    if (R.and(isOpen, R.equals(1, opacity))) return;

    const timer = setTimeout(() => setIsOpen(false), 200);

    return () => clearTimeout(timer);
  }, [isOpen, opacity]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box m={m} ref={ref} width={wrapperWidth}>
        <MuiTextInputForDatePicker
          {...inputSettings}
          label={label}
          readOnly={true}
          onClick={handleClick}
          inputStyles={{ width }}
          placeholder={placeholder}
          withoutBorder={withoutBorder}
          isFocused={R.and(isOpen, R.equals(1, opacity))}
          value={G.isNotNilAndNotEmpty(value) ?
            G.checkAndConvertMomentInstanceToFormattedDate(value, GC.DEFAULT_DATE_FORMAT) : ''
          }
        />
        <Popper
          open={isOpen}
          sx={{ zIndex: 1300 }}
          anchorEl={ref.current}
          placement={popperPlacement}
        >
          <Paper
            sx={{
              opacity,
              transform,
              borderRadius: '4px',
              transition: 'opacity 333ms cubic-bezier(0.4, 0, 0.2, 1), transform 222ms cubic-bezier(0.4, 0, 0.2, 1)',
              boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)', // eslint-disable-line
            }}
          >
            <StaticDatePicker
              autoFocus={true}
              onChange={onChange}
              onAccept={handleAccept}
              displayStaticWrapperAs='desktop'
              value={G.isNilOrEmpty(value) ? null : G.makeMomentInstance(value)}
              slotProps={{
                actionBar: G.ifElse(isClearable, { actions: ['clear'] }),
              }}
            />
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

const PureDatePicker = (props: Object) => {
  const {
    m,
    value,
    label,
    width,
    maxDate,
    minDate,
    handleClick,
    handleClose,
    wrapperWidth,
    withoutBorder,
    placeholder = '',
    autoFocus = false,
    datePickerInputRef,
    inputSettings = {},
    onChange = () => {},
    onAccept = () => {},
    isClearable = false,
    autoComplete = 'off',
    inputReadOnly = false,
    disablePortal = false,
    isDateDisabled = () => {},
    popperPlacement = 'bottom-end',
    inputFormat = GC.DEFAULT_DATE_FORMAT,
  } = props;

  const slotProps = useMemo(() => ({
    actionBar: G.ifElse(isClearable, { actions: ['clear'] }),
    popper: {
      disablePortal,
      placement: popperPlacement,
    },
    textField: {
      label,
      placeholder,
      handleClick,
      autoComplete,
      size: 'small',
      withoutBorder,
      inputReadOnly,
      inputSettings,
      onClose: handleClose,
      sx: {
        width,
        '.MuiInputBase-root': {
          height: 30,
          lineHeight: 'unset',
        },
        '.MuiOutlinedInput-input': {
          fontSize: 12,
          padding: '5px 0px 5px 8px',
        },
      },
    },
  }), []);

  return (
    <Box m={m} width={wrapperWidth}>
      <DesktopDatePicker
        sx={{ width }}
        maxDate={maxDate}
        minDate={minDate}
        onChange={onChange}
        onAccept={onAccept}
        autoFocus={autoFocus}
        slotProps={slotProps}
        onClose={handleClose}
        inputFormat={inputFormat}
        inputRef={datePickerInputRef}
        shouldDisableDate={isDateDisabled}
        value={G.isNilOrEmpty(value) ? null : G.makeMomentInstance(value)}
      />
    </Box>
  );
};

export const DatePickerMui = (props: Object) => {
  const { onOpen, onClose, useNewMuiInputField } = props;

  const { isOpen, setIsOpen, handleClick } = useDatePickerHandlers({ onOpen, onClose });

  if (useNewMuiInputField) {
    return (
      <DatePicker
        {...props}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClick={handleClick}
      />
    );
  }

  return <PureDatePicker {...props} />;
};

export const DatePickerMuiWithAutoOpenPickerOnMount = (props: Object) => {
  const { onOpen, onClose } = props;

  const {
    isOpen,
    anchorEl,
    setIsOpen,
    setAnchorEl,
    handleClose,
    handleClick,
  } = useDatePickerHandlers({ onOpen, onClose });

  const datePickerInputRef = useRef();

  useEffect(() => {
    setIsOpen(true);
    setAnchorEl(datePickerInputRef.current);
  }, [datePickerInputRef.current]);

  return (
    <PureDatePicker
      {...props}
      isOpen={isOpen}
      anchorEl={anchorEl}
      handleClose={handleClose}
      handleClick={handleClick}
      datePickerInputRef={datePickerInputRef}
    />
  );
};
