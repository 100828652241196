import * as R from 'ramda';
import { connect } from 'react-redux';
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  shouldUpdate,
  withHandlers,
} from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { LocalLoader } from '../local-loader';
// hooks
import useFixedPopover from '../../hooks/use-mui-fixed-popover';
// feature table
import { initialTableSettings } from './settings';
import ResizeActions from './components/resize-actions';
import {
  deleteAllResizeData,
  getReportTableResize,
  updateTableResizeByGuid,
  deleteTableResizeByGuid,
  deleteTableResizeByType,
} from '../../common/idb/resize/actions';
import {
  makeSelectIDB,
  makeSelectIDBProps,
  makeSelectResizeTable,
} from '../../common/idb/resize/selectors';
import { EmptyList, TableWrapper } from './ui';
import { TableBody, TableDimmer } from './components/table';
//////////////////////////////////////////////////

const TableComponent = (props: Object) => {
  const {
    report,
    loading,
    itemList,
    pagination,
    totalCount,
    resizeTable,
    columnSettings,
    withoutWaypoint,
    omitRenderFields,
    withResizableColumns,
    getReportTableResize,
    updateTableResizeByGuid,
  } = props;

  const guid = R.prop(GC.FIELD_GUID, report);
  const reportType = R.prop(GC.FIELD_TYPE, report);
  const notDraft = R.not(R.includes('draft', R.or(guid, '')));
  const isTableResizable = R.and(withResizableColumns, R.and(R.isNotNil(guid), notDraft));
  const resizeByGuid = R.pathOr({}, [guid], resizeTable);

  const [resizeObserver, setResizeObserver] = useState(null);

  const { PopoverComponent, openFixedPopup, closeFixedPopup } = useFixedPopover();

  const handleClickResetIcon = useCallback(({ currentTarget }: Object) => {
    const {
      handleClickResetByGuid,
      handleClickResetByType,
      handleResetAlternative,
    } = props;

    if (handleResetAlternative) return handleResetAlternative({ ...props, openFixedPopup, closeFixedPopup }, currentTarget);

    openFixedPopup({
      zIndex: 1300,
      position: 'right',
      el: currentTarget,
      content: (
        <ResizeActions
          closeFixedPopup={closeFixedPopup}
          reportType={R.prop(GC.FIELD_TYPE, report)}
          handleClickResetByGuid={handleClickResetByGuid}
          handleClickResetByType={handleClickResetByType}
        />
      ),
    });
  }, [report, openFixedPopup, closeFixedPopup]);

  const handleUpdateTableResize = useCallback((resizedTableField: Object) => {
    updateTableResizeByGuid({
      reportType,
      reportGuid: guid,
      resizedTableField,
    });
  }, [guid, reportType, updateTableResizeByGuid]);

  const createResizeObserver = useCallback(() => {
    // eslint-disable-next-line no-undef
    const observerInstance = new ResizeObserver(
      G.setDebounce((entries: Object) => {
        if (R.gt(R.prop('length', entries), 1)) return;

        if (R.pathOr(false, [0, 'borderBoxSize'], entries)) {
          const target = entries[0].target;
          const newWidth = R.pathOr(null, [0, 'borderBoxSize', 0, 'inlineSize'], entries);
          const fieldName = G.getPropFromObject(GC.FIELD_NAME, target);
          const storedWidth = R.prop(fieldName, resizeByGuid);
          const width = R.path([fieldName, 'width'], columnSettings);

          if (R.or(
            R.or(
              R.equals(newWidth, 0),
              R.equals(storedWidth, newWidth),
            ),
            R.and(
              R.isNil(storedWidth),
              R.equals(width, newWidth),
            ),
          )) return;

          handleUpdateTableResize({fieldName, width: newWidth});
        }
      }, 200),
    );

    setResizeObserver(observerInstance);
  }, [resizeByGuid, columnSettings, handleUpdateTableResize]);

  useEffect(() => {
    if (isTableResizable) {
      getReportTableResize(guid);
      createResizeObserver();
    }

    return () => {
      if (isTableResizable) {
        resizeObserver?.disconnect();
      }
    }
  }, [guid]);

  if (R.or(R.not(props.report), G.isNilOrEmpty(R.path(['report', 'fields'], props)))) {
    return (
      <TableWrapper maxHeight='fit-content'>
        <EmptyList
          p={R.pathOr(initialTableSettings.emptyListPadding, ['tableSettings', 'emptyListPadding'], props)}
          textAlign={R.pathOr(initialTableSettings.emptyListTextAlign, ['tableSettings', 'emptyListTextAlign'], props)}
        >
          {G.getWindowLocale('titles:create-report-to-see-data', 'Please, create a Report to see your data')}
        </EmptyList>
      </TableWrapper>
    );
  }

  const reportToUse = G.getReportSortedBySeqFreez(R.assoc(
    'fields',
    R.filter(
      (field: Object) => {
        const { name } = field;
        if (G.isNotNilAndNotEmpty(omitRenderFields)) return G.notContain(name, omitRenderFields);

        return G.notEquals(name, GC.FIELD_GUID);
      },
      R.pathOr([], ['fields'], report),
    ),
    report,
  ));

  const withWaypoint = G.isAllTrue(
    G.notEquals(withoutWaypoint, true),
    R.gt(totalCount, R.path(['offset'], pagination)),
    R.gte(R.path(['offset'], pagination), R.path(['limit'], pagination)),
  );

  const lastFreezedIndex = R.indexOf(
    R.findLast(
      R.propEq(true, 'freezed'),
      reportToUse.fields,
    ),
    reportToUse.fields,
  );

  const data = R.mergeDeepLeft(
    R.mergeRight(props, {
      lastFreezedIndex,
      report: reportToUse,
      handleClickResetIcon,
      withResizableColumns: isTableResizable,
    }),
    { tableSettings: initialTableSettings },
  );

  const condition = R.and(
    loading,
    G.isNilOrEmpty(itemList),
  );

  return (
    <LocalLoader
      alignItems='flex-start'
      width={props.mainWidth}
      localLoaderOpen={false}
      position={props.containedPosition}
      height={G.ifElse(
        data.tableSettings.fixLocalLoaderHeight,
        data.tableSettings.maxHeight,
      )}
    >
      <TableWrapper
        pb={props.tableWrapperPB}
        withoutBorder={props.withoutBorder}
        overflow={props.tableWrapperOverflow}
        minHeight={data.tableSettings.minHeight}
        maxHeight={data.tableSettings.maxHeight}
        width={R.or(props.tableInnerWidth, '100%')}
        {...G.spreadUiStyles(data.tableSettings.tableWrapperProps)}
      >
        {
          R.not(condition) &&
          <TableBody
            data={data}
            loading={loading}
            mainWidth={props.mainWidth}
            withWaypoint={withWaypoint}
            resizeByGuid={resizeByGuid}
            resizeObserver={resizeObserver}
            handleShowListIssues={props.handleShowListIssues}
            handleUpdateTableResize={handleUpdateTableResize}
          />
        }
        {
          condition &&
          <TableDimmer
            withHeader={true}
            minWidth={props.mainWidth}
            tableSettings={data.tableSettings}
            count={R.path(['pagination', 'limit'], props)}
          />
        }
      </TableWrapper>
      {PopoverComponent}
    </LocalLoader>
  );
};

const enhance = compose(
  withHandlers({
    handleClickResetByGuid: ({
      report,
      deleteTableResizeByGuid,
    }: Object) => () => {
      const guid = R.prop(GC.FIELD_GUID, report);

      if (R.isNil(guid)) return;

      deleteTableResizeByGuid(guid);
    },
    handleClickResetByType: ({
      report,
      deleteTableResizeByType,
    }: Object) => () => {
      const reportGuid = R.prop(GC.FIELD_GUID, report);
      const reportType = R.prop(GC.FIELD_TYPE, report);

      if (R.isNil(reportType)) return;

      deleteTableResizeByType({ reportType, reportGuid });
    },
  }),
  // TODO: check performance on the <Table>.
  pure,
  shouldUpdate((props: Object, nextProps: Object) => R.not(R.equals(props, nextProps))),
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  idb: makeSelectIDB(state),
  idbProps: makeSelectIDBProps(state),
  resizeTable: makeSelectResizeTable(state),
}));

export const Table = connect(mapStateToProps, {
  deleteAllResizeData,
  getReportTableResize,
  updateTableResizeByGuid,
  deleteTableResizeByGuid,
  deleteTableResizeByType,
})(enhance(TableComponent));

export { TitleRowComponent } from './components/table';
