import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { compose, withHandlers } from 'react-recompose';
// mui
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// components
import { TextComponent } from '../../text';
import { ActionBox } from '../../action-box';
// features
import { setExpandedContainerOptions } from '../../../features/expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { getFreightClassOptions } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { StyledLink } from '../../../ui';
// feature table
import { TableCell, FlexContainer } from '../ui';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.blueColor');

export const BooleanElement = ({ value, width, field }: Object) => (
  <TableCell
    width={width}
    minWidth={width}
    p={R.or(field.p, '8px')}
  >
    <FlexContainer height='100%' justify='center' direction='column'>
      {
        G.ifElse(
          G.isTrue(value),
          I.uiTrue,
          I.uiFalse,
        )()
      }
    </FlexContainer>
  </TableCell>
);

const renderEnumValue = (value: any) => {
  if (G.isArray(value)) {
    return R.compose(
      R.join(', '),
      R.map(G.getEnumLocale),
    )(value);
  }

  return G.getEnumLocale(value);
};

export const EnumElement = ({ value, width, field }: Object) => {
  const text = renderEnumValue(value);

  return (
    <TableCell
      width={width}
      minWidth={width}
      p={R.or(field.p, '8px')}
    >
      <FlexContainer height='100%' justify='center' direction='column'>
        <TextComponent
          title={text}
          display='block'
          overflow='hidden'
          withEllipsis={true}
          maxWidth={`calc(${width} - 8px)`}
        >
          {text}
        </TextComponent>
      </FlexContainer>
    </TableCell>
  );
};

const getFreightClassValues = (data: any) => {
  let dataArray = data;

  if (G.isString(data)) dataArray = R.of(Array, data);

  if (G.isNilOrEmpty(dataArray)) return '';

  return R.compose(
    R.join(', '),
    R.values,
    R.pick(dataArray),
    R.map(R.prop('label')),
    R.indexBy(R.prop(GC.FIELD_VALUE)),
  )(getFreightClassOptions());
};

export const FreightClassElement = ({ value, width, field }: Object) => (
  <TableCell
    width={width}
    minWidth={width}
    p={R.or(field.p, '8px')}
  >
    <FlexContainer height='100%' justify='center' direction='column'>
      {getFreightClassValues(value)}
    </FlexContainer>
  </TableCell>
);

export const ActionCell = ({ text, action, width, field }: Object) => (
  <TableCell
    width={width}
    minWidth={width}
    p={R.or(field.p, '8px')}
  >
    <FlexContainer height='100%' justify='center' direction='column'>
      <ActionBox text={text} action={action} />
    </FlexContainer>
  </TableCell>
);

const enhance = compose(
  connect(null, { setExpandedContainerOptions }),
  withHandlers({
    handleOpenExpandedContainer: (props: Object) => () => {
      const { rowGuid, componentType, visitPageGuid, setExpandedContainerOptions } = props;

      setExpandedContainerOptions({
        opened: true,
        componentType,
        visitPageGuid,
        options: { rowGuid },
      });
    },
  }),
);

export const ActionCellWithExpandedContainer = enhance(({
  text,
  width,
  field,
  handleOpenExpandedContainer,
}: Object) => (
  <TableCell
    width={width}
    minWidth={width}
    p={R.or(field.p, '8px')}
  >
    <FlexContainer height='100%' justify='center' direction='column'>
      <ActionBox text={text} action={handleOpenExpandedContainer} />
    </FlexContainer>
  </TableCell>
));

const convertors = {
  [GC.FIELD_WEIGHT]: (name: number) => G.fromKgsToPounds(name, 2),
  [GC.FIELD_DISTANCE]: (name: number) => G.fromKmsToMiles(name, 2),
  [GC.FIELD_ITEM_VOLUME]: (name: number) => G.fromCubicMeterToFeet(name, 2),
  [GC.FIELD_TEMPERATURE]: (name: number) => G.fromCelsiusToFahrenheit(name, 2),
};

const getValue = ({ name, type, uomSystem, withoutConversion }: Object) => {
  if (R.or(G.isTrue(withoutConversion), R.equals(uomSystem, GC.METRIC_SYSTEM))) return G.mathRoundNumber(name);

  return convertors[type](name);
};

const getCollectionValue = ({ name, type, uomSystem }: Object) => R.compose(
    R.join(', '),
    R.map((item: number) => getValue({ type, uomSystem, name: item })),
  )(R.or(name, []));

const metricUOMs = {
  [GC.FIELD_WEIGHT]: GC.UOM_KILOGRAM,
  [GC.FIELD_TEMPERATURE]: GC.UOM_CELSIUS,
  [GC.FIELD_DISTANCE]: GC.UOM_KILOMETER_LABEL,
  [GC.FIELD_ITEM_VOLUME]: GC.UOM_CUBIC_METERS,
};

const imperialUOMs = {
  [GC.FIELD_WEIGHT]: GC.UOM_POUND,
  [GC.FIELD_DISTANCE]: GC.UOM_MILE_LABEL,
  [GC.FIELD_ITEM_VOLUME]: GC.UOM_CUBIC_FEET,
  [GC.FIELD_TEMPERATURE]: GC.UOM_FAHRENHEIT,
};

export const UomRelatedField = ({ name, type, width, field, withoutConversion }: Object) => {
  const uomSystem = G.getConfigGeneralUomCalcDefaultUomSystemFromWindow();

  const collection = R.path(['collection'], field);

  const getValueFunc = G.ifElse(G.isTrue(collection), getCollectionValue, getValue);

  const value = getValueFunc({ name, type, uomSystem, withoutConversion });
  const uom = G.ifElse(R.equals(uomSystem, GC.METRIC_SYSTEM), metricUOMs[type], imperialUOMs[type]);

  return (
    <TableCell
      width={width}
      minWidth={width}
      p={R.or(field.p, '8px')}
    >
      <FlexContainer height='100%' justify='center' direction='column'>
        {value} {uom}
      </FlexContainer>
    </TableCell>
  );
};

export const CurrencyField = (props: Object) => {
  const { width, value, callbackData, field: { p } } = props;

  const currency = R.pathOr(
    R.or(G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY), GC.DEFAULT_UI_CURRENCY),
    [GC.FIELD_CURRENCY],
    callbackData,
  );

  const text = G.ifElse(G.isNilOrEmpty(value), '', `${G.getCurrencySymbol(currency)} ${G.mathRoundNumber(value)}`);

  return (
    <TableCell
      width={width}
      minWidth={width}
      p={R.or(p, '8px')}
    >
      <FlexContainer height='100%' justify='center' direction='column'>
        <TextComponent
          title={text}
          display='block'
          overflow='hidden'
          withEllipsis={true}
          maxWidth={`calc(${width} - 8px)`}
        >
          {text}
        </TextComponent>
      </FlexContainer>
    </TableCell>
  );
};

export const BranchElement = (props: Object) => {
  const { data, value, width, field } = props;

  const branchGuid = R.or(value, R.path([GC.FIELD_BRANCH_GUID], data));

  const title = G.getBranchNameFromWindowByGuid(branchGuid);

  return (
    <TableCell
      width={width}
      minWidth={width}
      p={R.or(field.p, '8px')}
    >
      {
        title &&
        <FlexContainer height='100%' justify='center' direction='column'>
          <TextComponent title={title} maxWidth='100%' withEllipsis={true}>
            {title}
          </TextComponent>
        </FlexContainer>
      }
    </TableCell>
  );
};

export const ClickableLink = (props: Object) => {
  const { value, width, field, settings } = props;

  const label = R.pathOr('actions:show', [GC.FIELD_LABEL], settings);
  const title = G.getWindowLocale(label, 'Show');

  return (
    <TableCell
      width={width}
      display='flex'
      minWidth={width}
      alignItems='center'
      p={R.or(field.p, '8px')}
    >
      {
        G.isNotNilAndNotEmpty(value) &&
        <StyledLink
          mr='6px'
          href={value}
          target='_blank'
          maxWidth='100%'
          color={blueColor}
          wordBreak='break-word'
        >
          {title}
        </StyledLink>
      }
    </TableCell>
  );
};

export const SelectDropdownMuiCell = (props: Object) => {
  const { data, value, width, field, settings, callbackData } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);
  const handleClick = ({ currentTarget }: Object) => setAnchorEl(currentTarget);

  const handleSelect = (value: string) => {
    const selectAction = G.getPropFromObject('selectAction', settings);

    if (G.isFunction(selectAction)) selectAction(value, data, callbackData);

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const options = R.pathOr([], ['selectOptions'], settings);
  const fieldName = G.getPropFromObject(GC.FIELD_NAME, field);
  const title = G.ifElse(R.equals(fieldName, GC.FIELD_STATUS), G.getEnumLocale(value), value);

  let permissions = R.pathOr([], ['selectPermissions'], settings);

  if (G.isFunction(permissions)) permissions = permissions(data);

  const textProps = {
    title,
    maxWidth: '100%',
    withEllipsis: true,
  };

  const tableCellPadding = G.getPropFromObject('p', field);

  const tableCellStyles = {
    width,
    display: 'flex',
    minWidth: width,
    p: R.or(tableCellPadding, '8px'),
  };

  const { editable, disabled } = data;

  if (G.isAnyTrue(
    G.isTrue(disabled),
    G.isFalse(editable),
    R.and(G.isNotNilAndNotEmpty(permissions), G.hasNotAmousCurrentUserPermissions(permissions)),
  )) {
    return (
      <TableCell {...tableCellStyles}>
        <TextComponent {...textProps}>
          {title}
        </TextComponent>
      </TableCell>
    );
  }

  return (
    <TableCell {...tableCellStyles} p={R.or(tableCellPadding, '8px 0px')}>
      <Button
        aria-haspopup='true'
        onClick={handleClick}
        id='select-dropdown-mui-button'
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'select-dropdown-mui-cell' : undefined}
        sx={{ fontSize: 'inherit', textTransform: 'none', color: G.getTheme('colors.light.blue') }}
      >
        <TextComponent {...textProps}>
          {title}
        </TextComponent>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        id='select-dropdown-mui-button-menu'
        slotProps={{ paper: { style: { maxHeight: 155 } } }}
        MenuListProps={{ 'aria-labelledby': 'select-dropdown-mui-button' }}
      >
        {
          G.isNotNilAndNotEmpty(options) &&
          options.map(({ value, label }: Object) => (
            <MenuItem key={value} onClick={() => handleSelect(value)}>
              {label}
            </MenuItem>
          ))
        }
      </Menu>
    </TableCell>
  );
};
