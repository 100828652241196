import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// hocs
import { withConnectModalActions } from '../../hocs';
// ui
import { Flex, ActionButton, CancelButton } from '../../ui';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkBlueColor = G.getTheme('colors.dark.blue');
const titleGreyColor = G.getTheme('colors.titleGrey');
const darkDarkBlueColor = G.getTheme('colors.dark.darkBlue');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const commonStyles = {
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
};

const defaultStyles = {
  ...commonStyles,
  height: 32,
  width: 120,
  p: '4px 8px',
  fontSize: 15,
};

const defaultCancelStyles = {
  ...defaultStyles,
  textColor: greyMatterhornColor,
  borderColor: greyMatterhornColor,
};

const defaultSubmitStyles = {
  ...defaultStyles,
  textColor: darkBlueColor,
  borderColor: darkBlueColor,
};

const defaultBoxStyles = {
  width: '100%',
  justifyContent: 'space-between',
};

export const FormFooter = withConnectModalActions((props: Object) => (
  <Flex {...G.mergeAndSpreadUiStyles(defaultBoxStyles, props.boxStyles)}>
    <CancelButton
      {...G.mergeAndSpreadUiStyles(defaultCancelStyles, props.cancelStyles)}
      type='button'
      onClick={R.or(props.cancelAction, props.closeModal)}
    >
      {R.pathOr(G.getWindowLocale('actions:cancel', 'Cancel'), ['cancelBtnText'], props)}
    </CancelButton>
    {
      G.isNotNilAndNotEmpty(props.actionButtons) &&
      props.actionButtons.map(({ type, action, disabled, displayText, buttonStyles }: Object, i: number) => (
        <ActionButton
          {...G.mergeAndSpreadUiStyles(defaultSubmitStyles, buttonStyles)}
          key={i}
          onClick={action}
          disabled={disabled}
          type={R.or(type, 'button')}
          cursor={G.ifElse(disabled, 'not-allowed')}
        >
          {displayText}
        </ActionButton>
      ))
    }
    <ActionButton
      {...G.mergeAndSpreadUiStyles(defaultSubmitStyles, props.submitStyles)}
      type='submit'
      form={props.formId}
      disabled={R.or(G.isTrue(props.isSubmitting), props.submitDisabled)}
      cursor={G.ifElse(R.or(G.isTrue(props.isSubmitting), props.submitDisabled), 'not-allowed')}
      onClick={() => {
        if (G.isFunction(R.path(['submitAction'], props))) props.submitAction();
      }}
    >
      {R.pathOr(G.getWindowLocale('actions:submit', 'Submit'), ['submitBtnText'], props)}
    </ActionButton>
  </Flex>
));

export const CancelSaveFooter = withConnectModalActions((props: Object) => (
  <Flex {...G.mergeAndSpreadUiStyles(defaultBoxStyles, props.boxStyles)}>
    <CancelButton
      {...G.mergeAndSpreadUiStyles(defaultCancelStyles, props.cancelStyles)}
      onClick={props.closeModal}
    >
      {R.pathOr(G.getWindowLocale('actions:cancel', 'Cancel'), ['cancelBtnText'], props)}
    </CancelButton>
    <ActionButton
      {...G.mergeAndSpreadUiStyles(defaultSubmitStyles, props.submitStyles)}
      disabled={R.or(G.isTrue(props.isSubmitting), props.submitDisabled)}
      cursor={G.ifElse(R.or(G.isTrue(props.isSubmitting), props.submitDisabled), 'not-allowed')}
      onClick={() => {
        if (G.isFunction(R.path(['submitAction'], props))) props.submitAction();
      }}
    >
      {R.pathOr(G.getWindowLocale('actions:save', 'Save'), ['saveBtnText'], props)}
    </ActionButton>
  </Flex>
));

export const ChargeFormFooter = (props: Object) => (
  <FormFooter
    boxStyles={{ p: '15px', bg: G.getTheme('colors.dark.mainLight') }}
    submitDisabled={G.ifElse(G.isBoolean(props.submitDisabled), props.submitDisabled, false)}
    submitStyles={{
      ...commonStyles,
      bgColor: whiteColor,
      textColor: darkDarkBlueColor,
      borderColor: darkDarkBlueColor,
    }}
    cancelStyles={{
      ...commonStyles,
      bgColor: whiteColor,
      textColor: titleGreyColor,
      borderColor: titleGreyColor,
    }}
  />
);

export const FormFooter2 = (props: Object) => (
  <FormFooter
    formId={props.formId}
    boxStyles={props.boxStyles}
    submitAction={props.submitAction}
    cancelAction={props.cancelAction}
    actionButtons={props.actionButtons}
    submitBtnText={props.submitBtnText}
    cancelBtnText={props.cancelBtnText}
    submitDisabled={G.ifElse(G.isBoolean(props.submitDisabled), props.submitDisabled, false)}
    submitStyles={{
      textColor: darkBlueColor,
      borderColor: darkBlueColor,
      ...G.mergeAndSpreadUiStyles(commonStyles, props.submitBtnStyles),
    }}
    cancelStyles={{
      textColor: greyMatterhornColor,
      borderColor: greyMatterhornColor,
      ...G.mergeAndSpreadUiStyles(commonStyles, props.cancelBtnStyles),
    }}
  />
);
