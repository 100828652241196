import * as R from 'ramda';
import React, { useState } from 'react';
import Popper from '@mui/material/Popper';
import { OuterClick } from 'react-outer-click';
// components
import { TextComponent } from '../text';
// helpers/constants
import * as G from '../../helpers';
// svgs
import * as I from '../../svgs';
// ui
import { Box, Flex, RelativeBox, PageTitleCount } from '../../ui';
// features routes
import getSettings from './settings';
import { Wrapper, PopperComponentWrapper } from './ui';
//////////////////////////////////////////////////

const DropDownOnHover = (props: Object) => {
  const {
    zi,
    open,
    rotate,
    content,
    position,
    children,
    anchorEl,
    usePortal,
    popperZIndex,
    wrapperWidth,
    handleTogglePopper,
  } = props;

  const zIndex = R.or(popperZIndex, zi);

  let isHovered = false;

  return (
    <PopperComponentWrapper
      wrapperWidth={wrapperWidth}
      onMouseEnter={handleTogglePopper}
      onMouseLeave={G.setDebounce(() => handleTogglePopper(null, isHovered), 300)}
    >
      <Box
        display='flex'
        transition='transform .3s linear'
        transform={G.ifElse(
          R.and(open, G.isTrue(rotate)),
          R.or(props.rotateDeg, 'rotate(90deg)'),
          'rotate(0deg)',
        )}
      >
        {children}
      </Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={position}
        disablePortal={R.not(usePortal)}
        sx={{ zIndex: G.ifElse(usePortal, 1300, zIndex) }}
      >
        <Wrapper
          {...props}
          onMouseEnter={() => isHovered = true}
          onMouseLeave={() => isHovered = false}
        >
          {content}
        </Wrapper>
      </Popper>
    </PopperComponentWrapper>
  );
};

const renderContent = ({ content, setAnchorEl }: Object) => {
  if (G.isFunction(content)) {
    const closePopper = () => setAnchorEl(null);

    return content({ closePopper });
  }

  return content;
};

const PopperContent = (props: Object) => {
  const { zi = 20, open, position, usePortal, anchorEl, popperZIndex, withoutOuterClick, handleTogglePopper } = props;

  const zIndex = R.or(popperZIndex, zi);

  const handleOuterClick = () => {
    if (withoutOuterClick) return;

    handleTogglePopper();
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={position}
      disablePortal={R.not(usePortal)}
      sx={{ zIndex: G.ifElse(usePortal, R.or(zIndex, 1300), zIndex) }}
    >
      <OuterClick
        {...props}
        as={Wrapper}
        onOuterClick={handleOuterClick}
      >
        {renderContent(props)}
      </OuterClick>
    </Popper>
  );
};

export const DropDownOnClick = (props: Object) => {
  const {
    open,
    rotate,
    children,
    rotateDeg,
    wrapperWidth,
    handleTogglePopper,
    popperComponentWrapperMargin,
  } = props;

  return (
    <PopperComponentWrapper
      wrapperWidth={wrapperWidth}
      onClick={handleTogglePopper}
      m={popperComponentWrapperMargin}
    >
      <Box
        display='flex'
        transition='transform .3s linear'
        transform={G.ifElse(
          R.and(open, G.isTrue(rotate)),
          R.or(rotateDeg, 'rotate(90deg)'),
          'rotate(0deg)',
        )}
      >
        {children}
      </Box>
      <PopperContent {...props} />
    </PopperComponentWrapper>
  );
};

export const PopperComponent = (props: Object) => {
  const { type, actionOnHoverOrClick } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleTogglePopper = (event: Object, isHovered: boolean) => {
    if (G.isAnyTrue(
      isHovered,
      G.isAllNilOrEmpty([event, anchorEl]),
      G.isAllNotNilOrNotEmpty([event, anchorEl]),
    )) return;

    if (R.and(R.isNil(anchorEl), G.isNotNil(actionOnHoverOrClick))) actionOnHoverOrClick();

    setAnchorEl(event ? event.currentTarget : null);
  };

  const dropDownType = {
    hover: DropDownOnHover,
    click: DropDownOnClick,
  };

  const componentProps = {
    ...props,
    anchorEl,
    setAnchorEl,
    handleTogglePopper,
    open: R.isNotNil(anchorEl),
  };

  return dropDownType[type](componentProps);
};

export const FilterWithCounter = (props: Object) => (
  <RelativeBox
    ml='5px'
    mt='5px'
    title={props.title}
  >
    {I.filter(props.iconColor)}
    <PageTitleCount
      r='-3px'
      t='-3px'
      width='14px'
      height='14px'
      fontSize='11px'
      border='1px solid'
      version={props.version}
    >
      {props.filterCount}
    </PageTitleCount>
  </RelativeBox>
);

export const PopperWrapper = (props: Object) => {
  const {
    type,
    icon,
    text,
    filterCount,
    popperContent,
    popperWithCount,
    additionalStyles,
    withoutOuterClick,
    actionOnHoverOrClick,
  } = props;

  const mainBlue = G.getTheme('colors.dark.blue');
  const mainLight = G.getTheme('colors.light.mainLight');
  const settings = getSettings({ mainBlue, mainLight });

  return (
    <PopperComponent
      {...R.path(['popper', type], settings)}
      {...additionalStyles}
      content={popperContent}
      withoutOuterClick={withoutOuterClick}
      actionOnHoverOrClick={actionOnHoverOrClick}
    >
      <Flex {...R.path(['popperContainer', type], settings)}>
        <Flex {...R.path(['text', type], settings)}>
          <TextComponent>
            {text}
          </TextComponent>
          {
            G.isNotNilAndNotEmpty(icon) &&
            <Box {...R.path(['icon', type], settings)}>{icon}</Box>
          }
        </Flex>
        {
          R.and(G.isTrue(popperWithCount), G.isNotNilAndNotZero(filterCount)) &&
          <FilterWithCounter
            {...R.path(['filterIcon', type], settings)}
            title={filterCount}
            filterCount={filterCount}
          />
        }
      </Flex>
    </PopperComponent>
  );
};
